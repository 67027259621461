import React, { Component } from "react"
import { Table, Input, Button, Row, Col, Alert, List, Popover } from "antd"
import styled, { css } from "styled-components"
import {
  LoadingOutlined,
  ThunderboltTwoTone,
  CopyOutlined,
  CheckCircleTwoTone,
} from "@ant-design/icons"
import { connect } from "react-redux"

import socket, { connectSocket } from "../socket"
import { setLoading, setCelebrate } from "../Common/actions"

const { Column } = Table

const ReconnectButton = styled(Button)`
  padding: 0;
`

const PointsContainer = styled.div`
  display: flex;
  justify-content: center;
`

const CenterAlert = styled(Alert)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const PointingTable = styled(Table)`
  background-color: black;

  .PointsParagraph {
    ${(props) =>
      props.hidePoints &&
      css`
        background: none;
      `}
  }
  .ant-table-cell {
    padding: 0px 8px;
  }
`

const PointingButton = styled(Button)`
  background-color: #f2d230;
  border-color: #f2d230;
  color: black;
  &:hover {
    background-color: #8f7c1d;
    border-color: #8f7c1d;
    color: black;
  }
  &:focus {
    background-color: #8f7c1d;
    border-color: #8f7c1d;
    color: black;
  }

  margin: ${(props) => (props.margin ? props.margin : "8px")};
`

const ShareButton = styled(PointingButton)`
  background-color: #d9c6bf;
  border-color: #d9c6bf;

  &:hover {
    background-color: #bdaca6;
    border-color: #bdaca6;
    color: black;
  }
  &:focus {
    background-color: #bdaca6;
    border-color: #bdaca6;
    color: black;
  }
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `};
`

const ShowClearContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const PointingPokerContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 707px;
  min-width: 320px;
  margin: 20px;
`

const TableContainer = styled.div`
  margin: 40px 0;
  max-width: 700px;
`

const PointsParagraph = styled.p`
  font-size: 14px;
  margin: 0;
`

const CopiedParagraph = styled(PointsParagraph)`
  margin-top: 10px;
`

const POINT_OPTIONS = [
  { text: "0 points", points: 0, order: 0 },
  { text: "1/2 point", points: 0.5, order: 1 },
  { text: "1 point", points: 1, order: 2 },
  { text: "2 points", points: 2, order: 3 },
  { text: "3 points", points: 3, order: 4 },
  { text: "5 points", points: 5, order: 5 },
  { text: "8 points", points: 8, order: 6 },
  { text: "13 points", points: 13, order: 7 },
  { text: "20 points", points: 20, order: 8 },
  { text: "40 points", points: 40, order: 9 },
  { text: "100 points", points: 100, order: 10 },
  { text: "¯\\_(ツ)_/¯", points: "?", order: 11 },
]

class PointingPokerLayout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sessionId: "",
      sessionUsers: [],
      username: "",
      joined: false,
      hidePoints: true,
      storyDescription: "",
      alert: false,
      avgScore: 0,
      copySuccess: false,
      visiblePopover: false,
    }

    this.celebrateAlreadyTriggered = false

    this.createPointingSession = this.createPointingSession.bind(this)
    this.onSessionIdReceived = this.onSessionIdReceived.bind(this)
    this.getUsers = this.getUsers.bind(this)
    this.onSessionUsersReceived = this.onSessionUsersReceived.bind(this)
    this.onUserPointsReceived = this.onUserPointsReceived.bind(this)
    this.handleClearPoints = this.handleClearPoints.bind(this)
    this.handleDisconnect = this.handleDisconnect.bind(this)
    this.handleStoryDescriptionSubmit = this.handleStoryDescriptionSubmit.bind(
      this,
    )
    this.onStoryDescriptionReceived = this.onStoryDescriptionReceived.bind(this)
    this.enterPressed = this.enterPressed.bind(this)
    this.copyToClipboard = this.copyToClipboard.bind(this)
  }

  componentDidMount() {
    connectSocket()
    socket().registerSession(this.onSessionIdReceived)
    socket().registerGetPointingSessionUsers(this.onSessionUsersReceived)
    socket().registerUserPoints(this.onUserPointsReceived)
    socket().registerClearPoints(this.handleClearPoints)
    socket().registerShowPoints(this.handleShowPoints)
    socket().registerStoryDescription(this.onStoryDescriptionReceived)
    socket().onDisconnect(this.handleDisconnect)
    const sessionId = this.props.match.params.sessionId
    if (sessionId) {
      this.setState({ sessionId })
    }
  }

  componentWillUnmount() {
    const { sessionId } = this.state
    socket().unregisterSession(sessionId)
  }

  handleDisconnect = () => {
    this.setState({ alert: true })
  }

  onStoryDescriptionReceived = (storyDescription) => {
    this.setState({ storyDescription })
  }

  onUserPointsReceived = (pointsReceived) => {
    const { sessionUsers } = this.state
    const { username, points } = pointsReceived

    let usersPointed = 0

    const updatedUsers = sessionUsers.map((user) => {
      if (user.username === username) {
        usersPointed++
        return {
          username,
          points,
        }
      }

      if (user.points !== "") {
        usersPointed++
      }

      return user
    })

    if (usersPointed === sessionUsers.length) {
      this.handleShowPoints(true)
    }
    this.setState({ sessionUsers: updatedUsers })
    this.calculateAvgScore(updatedUsers)
  }

  calculateAvgScore(updatedUsers) {
    const suffragingUsers = updatedUsers.filter(
      (user) => typeof user.points !== "string" && user.points,
    )

    const avgScore = parseFloat(
      updatedUsers
        .map((val) => val.points)
        .reduce((accumulator, currentValue) => {
          if (typeof currentValue !== "string") {
            return accumulator + currentValue
          }
          return accumulator
        }) / suffragingUsers.length,
    )
    this.setState({ avgScore })
  }

  onSessionIdReceived = (sessionId) => {
    const { setLoading } = this.props
    this.props.history.push(`/pointing/${sessionId}`)
    this.setState({ sessionId })
    this.getUsers(sessionId)
    setLoading(false)
  }

  onSessionUsersReceived = (userList) => {
    const { setLoading } = this.props
    this.setState({ sessionUsers: userList, joined: true, hidePoints: true })
    setLoading(false)
  }

  createPointingSession = (username) => {
    const { setLoading } = this.props
    setLoading(true)
    socket().createPointingSession(username)
    socket().submitStoryDescription("")
  }

  joinPointingSession = (sessionId, username) => {
    const { setLoading } = this.props
    setLoading(true)
    socket().joinPointingSession(sessionId, username)
    socket().getStoryDescription()
  }

  getUsers = (sessionId) => {
    socket().getPointingSessionUsers(sessionId)
  }

  handleUsername = (e) => {
    this.setState({ username: e.target.value })
  }

  handlePointClick = (points) => {
    const { username } = this.state
    this.onUserPointsReceived({ username, points })
    socket().submitPoints(points)
  }

  handleClearPoints = (emit) => {
    const { sessionUsers } = this.state
    const clearPoints = sessionUsers.map((user) => ({
      username: user.username,
      points: "",
    }))
    
    this.celebrateAlreadyTriggered = false
    this.setState({
      sessionUsers: clearPoints,
      hidePoints: true,
      storyDescription: "",
    })
    if (emit) {
      socket().clearPoints()
    }
  }

  handleShowPoints = (emit) => {
    const { sessionUsers } = this.state
    this.setState({ hidePoints: false })

    const celebrate =
      sessionUsers.length > 3 &&
      sessionUsers.filter((user, i, array) => user.points === array[0].points)
        .length === sessionUsers.length

    if (celebrate && !this.celebrateAlreadyTriggered) {
      this.triggerCelebration()
      this.celebrateAlreadyTriggered = true
    }

    if (emit) {
      socket().showPoints()
    }
  }

  handleStoryDescription = (e) => {
    this.setState({ storyDescription: e.target.value })
  }

  handleStoryDescriptionSubmit = () => {
    const { storyDescription } = this.state
    socket().submitStoryDescription(storyDescription)
  }

  enterPressed = () => {
    const { sessionId, username } = this.state

    if (sessionId) {
      this.joinPointingSession(sessionId, username)
    } else {
      this.createPointingSession(username)
    }
  }

  copyToClipboard = (e) => {
    this.inputArea.select()
    document.execCommand("copy")
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    e.target.focus()
    this.setState({ copySuccess: true })
  }

  handleVisibleChange = (visible) => {
    this.setState({ visiblePopover: visible, copySuccess: false })
  }

  triggerCelebration() {
    const { setCelebrate } = this.props

    setCelebrate(true)
    setTimeout(() => {
      setCelebrate(false)
    }, 3000)
  }

  render() {
    const {
      sessionId,
      sessionUsers,
      username,
      joined,
      hidePoints,
      storyDescription,
      alert,
      avgScore,
      copySuccess,
      visiblePopover,
    } = this.state

    const PopOverContent = () => (
      <React.Fragment>
        <Input.Group size="large" compact>
          <Input
            style={{ width: "80%", height: "40px" }}
            value={`https://sprintspark.io/pointing/${sessionId}`}
            ref={(inputArea) => (this.inputArea = inputArea)}
          />
          <Button
            style={{ width: "20%", height: "40px" }}
            size="middle"
            icon={<CopyOutlined />}
            onClick={this.copyToClipboard}
          />
        </Input.Group>
        {copySuccess && (
          <CopiedParagraph>
            <CheckCircleTwoTone
              style={{ marginRight: "5px" }}
              twoToneColor="#52c41a"
            />
            Copied!
          </CopiedParagraph>
        )}
      </React.Fragment>
    )

    if (alert) {
      return (
        <CenterAlert
          message="Warning"
          description={
            <PointsParagraph>
              {"Looks like you have been disconected, "}
              <ReconnectButton
                type="link"
                onClick={() => window.location.reload()}>
                click here to reconnect to your session
              </ReconnectButton>
            </PointsParagraph>
          }
          type="warning"
          showIcon
        />
      )
    }
    return (
      <PointingPokerContainer>
        <div className="section">
          <h1>
            {sessionId
              ? `The session name is: ${sessionId}`
              : `Create a new session!`}
          </h1>
        </div>
        <InputContainer maxWidth="400px">
          {!sessionUsers.length && (
            <Input
              placeholder="Enter your username"
              required
              maxLength={72}
              onChange={this.handleUsername}
              onPressEnter={this.enterPressed}
            />
          )}
          {!sessionUsers.length && sessionId && (
            <PointingButton
              type="primary"
              margin="none"
              onClick={() => this.joinPointingSession(sessionId, username)}>
              Join Pointing Session
            </PointingButton>
          )}
          {!sessionUsers.length && !sessionId && (
            <PointingButton
              type="primary"
              margin="none"
              onClick={() => {
                this.createPointingSession(username)
              }}>
              Create new Pointing Session
            </PointingButton>
          )}
        </InputContainer>
        {joined && (
          <>
            <InputContainer>
              <Input
                value={storyDescription}
                placeholder="Story description/ticket #"
                onChange={this.handleStoryDescription}
                maxLength={177}
              />
              <PointingButton
                type="primary"
                margin="none"
                onClick={this.handleStoryDescriptionSubmit}>
                Submit Description
              </PointingButton>
            </InputContainer>
            <ShowClearContainer>
              <PointingButton onClick={() => this.handleShowPoints(true)}>
                Show Points
              </PointingButton>
              <PointingButton onClick={() => this.handleClearPoints(true)}>
                Clear Points
              </PointingButton>
              <Popover
                title="Share this session by sending this link"
                trigger="click"
                placement="bottom"
                content={PopOverContent}
                visible={visiblePopover}
                onVisibleChange={this.handleVisibleChange}>
                <ShareButton>Share Session</ShareButton>
              </Popover>
            </ShowClearContainer>
            <Row gutter={16}>
              {POINT_OPTIONS.map((option) => (
                <Col span={7} key={option.order} xs>
                  <PointingButton
                    onClick={() => this.handlePointClick(option.points)}>
                    {option.text}
                  </PointingButton>
                </Col>
              ))}
            </Row>
            {!hidePoints && (
              <List header={<div>Summary</div>}>
                <List.Item>Average Score: {avgScore}</List.Item>
              </List>
            )}
            <TableContainer>
              <PointingTable
                dataSource={sessionUsers}
                pagination={false}
                bordered>
                <Column title="Playa" dataIndex="username" key="username" />
                <Column
                  title="Points"
                  dataIndex="points"
                  key="points"
                  width="20px"
                  render={(text, record) => {
                    if ((text && record.username === username) || !hidePoints) {
                      return (
                        <PointsContainer>
                          <PointsParagraph>{text}</PointsParagraph>
                        </PointsContainer>
                      )
                    }
                    if (hidePoints && text) {
                      return (
                        <PointsContainer>
                          <ThunderboltTwoTone
                            style={{ fontSize: "20px" }}
                            twoToneColor="#F2D230"
                          />
                        </PointsContainer>
                      )
                    }
                    return (
                      <PointsContainer>
                        <LoadingOutlined
                          style={{ fontSize: "20px" }}
                          twoToneColor="#F2D230"
                        />
                      </PointsContainer>
                    )
                  }}
                />
              </PointingTable>
            </TableContainer>
          </>
        )}
      </PointingPokerContainer>
    )
  }
}

PointingButton.defaultProps = {
  size: "small",
}

ShareButton.defaultProps = {
  size: "small",
}

Input.defaultProps = {
  size: "small",
}

const mapDispatchToProps = (dispatch) => ({
  setLoading: (bool) => dispatch(setLoading(bool)),
  setCelebrate: (bool) => dispatch(setCelebrate(bool)),
})

export default connect(null, mapDispatchToProps)(PointingPokerLayout)
