import { SET_LOADING, SET_CELEBRATE } from "./actionTypes"

export const setLoading = (payload) => {
  return {
    type: SET_LOADING,
    payload,
  }
}

export const setCelebrate = (payload) => {
  return {
    type: SET_CELEBRATE,
    payload,
  }
}
