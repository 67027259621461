import io from "socket.io-client"
import { socketUrl } from "./utils/config"

let socket

export const connectSocket = () => {
  socket = io.connect(socketUrl)
}

export default function () {
  function registerSession(onSessionReceived) {
    socket.on("sessionId", onSessionReceived)
  }

  function registerGetPointingSessionUsers(onSessionUsersReceived) {
    socket.on("sessionUsers", onSessionUsersReceived)
  }

  function registerUserPoints(onUserPointsReceived) {
    socket.on("userPoints", onUserPointsReceived)
  }

  function registerClearPoints(onClearPointsReceived) {
    socket.on("clearUserPoints", onClearPointsReceived)
  }

  function registerShowPoints(onShowPointsReceived) {
    socket.on("showUserPoints", onShowPointsReceived)
  }

  function registerStoryDescription(onStoryDescriptionReceived) {
    socket.on("setStoryDescription", onStoryDescriptionReceived)
  }

  function showPoints() {
    socket.emit("showPoints")
  }

  function unregisterSession() {
    socket.emit("leavePointingSession")
  }

  function submitPoints(points) {
    socket.emit("points", points)
  }

  function createPointingSession(username) {
    socket.emit("createPointingSession", username)
  }

  socket.on("error", function (err) {
    console.log("received socket error:")
    console.log(err)
  })

  function register(name, cb) {
    socket.emit("register", name, cb)
  }

  function joinPointingSession(sessionId, username) {
    socket.emit("joinPointingSession", { sessionId, username })
    getPointingSessionUsers(sessionId)
  }

  function getPointingSessionUsers() {
    socket.emit("getPointingSessionUsers")
  }

  function clearPoints() {
    socket.emit("clearPoints")
  }

  function onDisconnect(handleDisconnect) {
    socket.on("disconnect", handleDisconnect)
  }

  function submitStoryDescription(storyDescription) {
    socket.emit("storyDescription", storyDescription)
  }

  function getStoryDescription() {
    socket.emit("getStoryDescription")
  }

  return {
    register,
    joinPointingSession,
    registerGetPointingSessionUsers,
    getPointingSessionUsers,
    registerSession,
    createPointingSession,
    unregisterSession,
    submitPoints,
    registerUserPoints,
    clearPoints,
    registerClearPoints,
    onDisconnect,
    showPoints,
    registerShowPoints,
    submitStoryDescription,
    registerStoryDescription,
    getStoryDescription,
  }
}
