import { combineReducers } from "redux"
import loadingReducer from "./loadingReducer"
import celebrateReducer from "./celebrateReducer"

const rootReducer = combineReducers({
  loading: loadingReducer,
  celebrate: celebrateReducer,
})

export default rootReducer
