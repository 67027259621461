import "antd/dist/antd.dark.css"
import React, { Component } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import styled from "styled-components"
import PointingPoker from "./PointingPoker"
import Loading from "./Common/Loading"
import Celebrate from "./Common/Celebrate"

const AppContainer = styled.div`
  display: flex;
  justify-content: center;
`

class App extends Component {
  render() {
    return (
      <AppContainer className="App">
        <Loading />
        <Celebrate />
        <AppRouter />
      </AppContainer>
    )
  }
}

function AppRouter() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={PointingPoker} />
        <Route exact path="/pointing/:sessionId" component={PointingPoker} />
      </Switch>
    </Router>
  )
}

export default App
