import React from "react"
import { Spin } from "antd"
import { connect } from "react-redux"
import styled from "styled-components"

const SpinnerContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  opacity: 0.8;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
`

function Loading({ loading, children }) {
  if (loading) {
    return (
      <SpinnerContainer>
        <Spin tip="Loading..." />
      </SpinnerContainer>
    )
  }
  return null
}

const mapStateToProps = (state) => {
  const { loading } = state
  return { loading }
}

export default connect(mapStateToProps)(Loading)
